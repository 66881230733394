@import "~react-image-gallery/styles/css/image-gallery.css";
@font-face {
  font-family: 'chakra';
  /* src: url('forgotten-futurist.rg-regular.otf') format('truetype'); */
  src: url('ChakraPetch-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Linebeam';
  /* src: url('forgotten-futurist.rg-regular.otf') format('truetype'); */
  src: url('Linebeam.ttf') format('truetype');
}

.App {
  text-align: center;
  background-color: #100F12;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logotipo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #100F12;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(220, 215, 221);
  font-family: 'chakra', sans-serif;
  font-weight: 100;
  letter-spacing: 1.5px;
}

.GalleryContainer{
  width: 70vw;
  height: auto;
}

.pageContainer .GalleryContainer{
  width: 35vw;
  height: auto;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  padding-left: 17vw;

  @media (max-width: 768px) {
    width: 80vw;
    padding-left: 3vw;
  }
  @media (max-width: 412px){
    width: 70vw;
    padding-left: 7vw;
    margin: 0;
  }

}

.navlink{
  text-decoration: none;
  color: rgb(220, 215, 221);
}

.Navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: #161617;
  width: 100%;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #3d3b3f; /* White border at the bottom */
}

.Navigation ul{
  margin: 15px;
  padding: 0;
}
.Navigation ul li {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.Navigation .logo img{
  width: 150px; /* Adjust the size as needed */
  height: auto; /* Maintain aspect ratio */
  padding-left: 105px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.Navigation .logotipo img{
  width: 250px; /* Adjust the size as needed */
  height: auto; /* Maintain aspect ratio */
  margin: 0;
  padding-top: 5px;
  padding-left: 2vw;
}

.DashboardComponent{
  background-color: #161617;
  color: #F0EBFA;
  width: 77vw;
  padding: 1.5vw;
  margin: 7vh;
  border-radius: 15px;
  @media (max-width: 768px) {
    margin: 3vh;
    width: 90vw;
  }
}

.cover-image {
  width: 77vw;
  height: auto;
  overflow: hidden; /* esto asegura que la imagen no se desborde del contenedor */
  padding: 0;
  margin-top: 11vh;
  @media (max-width: 768px) {
    margin-top: 12vh;
    width: 95vw;
  }
  @media(max-width: 500px) {
    margin-top: 15vh;
  }
}

.cover-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  padding: 0;
  margin: 0;
  border-radius: 9px;
}


/* ABOUT */
.about{
  background-color: #161617;
  color: #F0EBFA;
  width: 77vw;
  border-radius: 3px;
  background-color: #161617;
  padding: 1.5vw;
  margin-bottom: 10vh;
  @media (max-width: 768px) {
    width: 89vw;
    padding-left: 3vw;
  }
  @media (max-width: 412px){
    width: 85vw;
    padding-left: 7vw;
    margin: 0;
  }
}

.aboutContainer{
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 5%;
  padding-top: 2%;
  padding-left: 2%;
  padding-right: 2%;
  @media (max-width: 768px) {
    padding: 0;
    display: block;
    padding-top: 0%;
    padding-left: 0%;
    padding-right: 0%;
  }
}

.aboutLogo img{
  width: 100%;
  height: auto;
  padding-top: 1%;
  @media (max-width: 768px) {
    width: 40%;
    padding-top: 1.5%;
  }
  @media (max-width: 550px) {
    padding-top: 5%;
    width: 75%;
  }
}

.aboutText{
  text-align: justify;
  font-size: large;
  @media (max-width: 768px) {
    padding-top: 0;
    padding-left: 2%;
    padding-right: 2%;
    font-size: medium;
  }
  @media (max-width: 390px) {
    font-size: small;
  }
}
.aboutTitle{
  font-family: 'Linebeam', sans-serif;
  font-size: x-large;
  text-align: center;
  @media (max-width: 768px) {
    font-size: small;
  }
}

.dashGridContainer{
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
  font-size: small;
  
  /* Estilos para pantallas más pequeñas */
  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.dashGrid-item{
  padding: 20px;
  /* border: 2px solid #726E76; */
  border-radius: 7px;
  padding: 3px;
  margin: 0;
  transition: background-color 0.3s ease;
  list-style: none;

  @media (max-width: 768px) {
    font-size: 8px;
  }
}

.dashGrid-item img{
  /* border-top-left-radius: 7px; /* Cambia el valor para ajustar el redondeo */
  /* border-top-right-radius: 7px; */
  border-radius: 7px;
  margin: 3px;
}

.dashGrid-item hr{
  padding: 0;
  margin: 0;
}

.dashGrid-item img{
  width: 100%;
  height: auto;
  object-fit: cover;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 1px;
}

.dashGrid-item:hover{
  background: rgb(66,57,80);
  background: radial-gradient(circle, rgba(66,57,80,1) 0%, rgba(38,37,41,1) 86%);
 }

.pageContainer{
  /* background-color: #161617; */
  color: #F0EBFA;
  width: 77vw;
  padding: 1vw;
  margin: 7vh;
  border-radius: 10px;

  @media (max-width: 768px) {
    margin: 10vh;
    width: 90vw;
  }
}
.pageContainer .pageSec{
  background-color: #161617;
  border-radius: 10px;
  padding: 1vw;
  margin: 1.5vw;
}

.pageContainer h4{
  @media (max-width: 768px) {
    font-size: large;
  }
}
.pageContainer p{
  @media(max-width: 500px) {
    font-size: 15px;
  }
}

.pageContainer h1{
  text-align: left;
  margin-left: 5vw;
  color: #D6DEE6;
}


.yVideo iframe{
  width: 50vw; /* Ajusta el ancho según tus necesidades */
  height: 55vh; /* Ajusta la altura según tus necesidades */

  @media (max-width: 768px) {
    width: 55vh; /* Ajusta el ancho según tus necesidades */
    height: 50vw; /* Ajusta la altura según tus necesidades */
  }
  @media(max-width: 500px) {
    width: 70vw; /* Ajusta el ancho según tus necesidades */
    height: 50vw; /* Ajusta la altura según tus necesidades */
  }
}

.textoVideo{
  align-items: center;
  text-align: justify;
  margin-left: 10vw;
  margin-right: 10vw;
  font-size: 25px;
  @media (max-width: 768px) {
    margin-left: 5vw;
    margin-right: 5vw;
    font-size: 20px;
  }
}

hr{
  border-color:rgb(94, 92, 95);
  border-width: 3px;
}

.modal{
  position: fixed;
  z-index: 900;
  top:0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0,0,0.75);
  opacity: 0.9;
}

.modal-content{
  margin-top: 5%;
  background-color: #c1bbc7;
  margin-left: 15%;
  margin-right: 15%;
  padding-top: 2%;
  padding-bottom: 5%;
  color: #100F12;
  border-radius: 15px;
  opacity: .9;
  font-size: xx-large;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: medium;
    margin-top: 20%;
    margin-left: 3%;
    margin-right: 3%;

  }
  @media (max-width: 390px) {
    font-size: medium;

  }
}

.modal-content h5{
  color: #960619;
}

.modal-content p{
  padding-left: 10%;
  padding-right: 10%;
  text-align: justify;
}

.btnAviso{
  width: 10vw;
  height: 5vh;
  font-size: large;
  border-radius: 7px;
  font-weight: 700;
  background-color: #b7b5bb;
  color: #363638;
  font-family: 'chakra', sans-serif;
  @media (max-width: 768px) {
    font-size: medium;
    margin-top: 15%;
    width: 10vh;
    height: 10vw;
  }
  @media (max-width: 390px) {
    font-size: medium;
  }
}

.btnAviso:hover{
  background-color: #6d667a;
  color: #ffffff;
}

.gridAviso{
  display: inline-flex;   
}
.gridAvisoItem {
  margin: 2%;
}